import { createContext, useState, useEffect } from "react";
import Instance from "./Instance";
import config from "./config.js"

// Créez un contexte
const DataContext = createContext();

// Créez un provider pour envelopper vos composants et gérer les données partagées
const DataProvider = ({ children }) => {
  //Products
  const [products, setProducts] = useState([]);

  //Licenses
  const [licenses, setLicenses] = useState([]);

  //utilisateurs
  const [users, setUsers] = useState([]);

  //Co-abonnements
  const [subscriptions, setSubscriptions] = useState([]);

  //CAtegories
  const [categories, setCategories] = useState([]);

    //Partages
    const [shares, setShares] = useState([]);

    //Commandes
    const [orders, setOrders] = useState([]);

    //Blog 
    const [posts, setPosts] = useState([]);


  //cathegories 
  const [music, setMusic] = useState([]);
  const [video, setVideo] = useState([]);
  const [securite, setSecurite] = useState([]);
  const [services, setServices] = useState([]);
  const [design, setDesign] = useState([]);
  const [carteCadeauEtJeux, setCarteCadeauEtJeux] = useState([]);
  const [education, setEducation] = useState([]);
  const [error, setError] = useState("");

  // Connection (on chercher à savoir si l'utlisateur s'est conencté ou pas) 
  const [isBooleanValueAdmin, setIsBooleanValueAdmin] = useState(() => {
    // Récupérer la valeur depuis le localStorage lors de l'initialisation
    const storedValue = localStorage.getItem('isBooleanValue');
    // Utiliser la valeur stockée ou false si elle n'existe pas
    return storedValue ? JSON.parse(storedValue) : false;
  });

  const toggleBooleanValue = () => {
    // Inverser la valeur actuelle
    const updatedValue = !isBooleanValueAdmin;
    // Mettre à jour l'état
    setIsBooleanValueAdmin(updatedValue);
    // Stocker la nouvelle valeur dans le localStorage
    localStorage.setItem('isBooleanValueAdmin', JSON.stringify(updatedValue));
  };




  const fetchData = async () => {
    try {
      const result = await Instance.get(`${config.api_url}/products`);

      setProducts(result.data.products);
      setVideo(result.data.products.filter(product => product.productCategory === "Streaming Video"));
      setMusic(result.data.products.filter(product => product.productCategory === "Music"));
      setCarteCadeauEtJeux(result.data.products.filter(product => product.productCategory === "Cartes Cadeaux et Jeux Video"));
      setEducation(result.data.products.filter(product => product.productCategory === "Education"));
      setSecurite(result.data.products.filter(subscriber => subscriber.status === "Securite"));
      setDesign(result.data.products.filter(subscriber => subscriber.status === "Design"));
      setServices(result.data.products.filter(product => product.productCategory === "Services"));

    
    } catch (error) {
      console.error("-+-+-+-Erreur lors de la recupération des subscribers:", error);
      setError(error);
    }
    
  };



  const userConnected = localStorage.getItem("token")

    useEffect(() => {
    //Blog : Posts
    const fetchDataPosts = async () => {
      const result = await Instance.get(`${config.api_url}/posts`);
      setPosts(result.data.posts);
    };
      //Partages
  const fetchDataShares = async () => {
    const result = await Instance.get(`${config.api_url}/shares`);
    setShares(result.data.shares);
  };

    //License 
    const fetchDataLicenses = async () => {
      const result = await Instance.get(`${config.api_url}/licenses`);
      setLicenses(result.data);
    };
  
    //Users
    const fetchDataUser = async () => {
        const result = await Instance.get(`${config.api_url}/users`);
        setUsers(result.data.users);
    };
  
    //co-abonnements
    const fetchDataSubscriptions = async () => {
      const result = await Instance.get(`${config.api_url}/subscriptions`);
      setSubscriptions(result.data.subscriptions);
    };

    const fetchDataCategories = async () => {
      try {
          const result = await Instance.get(`${config.api_url}/categories`);
          setCategories(result.data.categories || []);
      } catch (error) {
          console.error("Error fetching categories:", error);
          setError(error);
      }
  };

      if (userConnected) {
        fetchData();
        fetchDataLicenses();
        fetchDataUser();
        fetchDataSubscriptions();
        fetchDataShares();
        fetchDataPosts();
        fetchDataCategories();
      }
    }, [userConnected]);
    

  // console.log(categories);
  return (
    <DataContext.Provider
      value={{
        toggleBooleanValue,
        products,
        licenses,
        music,
        video,
        securite,
        services,
        design,
        carteCadeauEtJeux,
        education,
        categories,
        

        error,
        users,
        subscriptions,
        shares,
        orders,
        posts
      }}
    >
      {children}
    </DataContext.Provider>
  );
};

export { DataContext, DataProvider };
