import React, { useContext } from 'react';
import { DataContext } from '../Services/DataContext';
import MainLayout from '../Layouts/MainLayout';
import SideBar from '../Layouts/SideBar';
import CustomList from '../Layouts/CustomList';
// import { useParams } from 'react-router-dom';

function License() {

    const { licenses } = useContext(DataContext);
    const barParams = { nav1: 'Nom du Produit', nav2: 'Nom License', nav3: 'N° de compte', nav4: 'status', nav5: "Utilisateurs" };
    const titleItem = { name: "Licenses", title1: "Nouveau License", title2: "Nombre de License", title3: "License", title4: "License" }
    const linkItem = { link1: "/dashboard/licenses/new-license", link2: "#", link3: "#", link4: "#" }
    const linkList = { show: `/dashboard/licenses/show-license`, edit: "/dashboard/licenses/edit-license" }
    // console.log(products);
    return (
        <div className='Product-layout'>
            <SideBar />
            <MainLayout
                titleItem={titleItem}
                linkItem={linkItem}
                numberItem={licenses.length}

            />
            <CustomList
                barParams={barParams}
                listItems={[...licenses].reverse()}
                linkList={linkList}
                key1="productName"
                key2="name"
                key3="number"
                key4="status"
                key5="userName"
                idKey="_id"
                urlDelete="delete-license"

            />
        </div>
    );
}

export default License;
