import React, { useEffect, useState } from "react";
import Instance from "../../Services/Instance";
import config from "../../Services/config.js";
import SideBar from "../../Layouts/SideBar";
import "../../Assets/Styles/Products.css"; 

import { useParams } from "react-router-dom";

function ShowOrder() {
    const [error, setError] = useState("");
    const [order, setOrder] = useState([]);
    const { id } = useParams();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const result = await Instance.get(
                    `${config.api_url}/show-order/${id}`
                );
                setOrder(result.data.order);
            } catch (error) {
                setError(error);
                console.log(error);
            }
        };

        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    const formattedDate = (_date) => {
        const dateObject = new Date(_date);
        return dateObject.toLocaleDateString('fr-FR')
    }
    // console.log(order);
    return (
        <div className="a-new-product">
            <SideBar />
            <div className="parent-new-product">
                <div className="show-order custom-layout-product">
                    
                        <form className="new-product">
                            <div className="form-display">
                                <div>
                                    <label htmlFor="orderNumber">Order Number</label>
                                    <input
                                        className="input"
                                        name="orderNumber"
                                        value={order.orderNumber}
                                        type="number"
                                        required={true}
                                    />
                                </div>

                                <div>
                                    <label htmlFor="orderDate">Order Date</label>
                                    <input
                                        className="input"
                                        name="orderDate"
                                        value={formattedDate(order.orderDate)}
                                        type="text"
                                        required={true}
                                    />
                                </div>

                                <div>
                                    <label htmlFor="CategoryName">Category Name</label>
                                    <input
                                        className="input"
                                        name="CategoryName"
                                        value={order.CategoryName}
                                        type="text"
                                        required={true}
                                    />
                                </div>
                                
                                <div>
                                    <label htmlFor="orderType">Order Type</label>
                                    <input
                                        className="input"
                                        name="orderType"
                                        value={order.orderType}
                                        type="text"
                                        required={true}
                                    />
                                </div>

                                <div>
                                    <label htmlFor="orderQuantity">Order Quantity</label>
                                    <input
                                        className="input"
                                        type="number"
                                        name="orderQuantity"
                                        value={order.orderQuantity}
                                        min={1}
                                        required
                                    />
                                </div>

                                <div>
                                    <label htmlFor="orderPrice">Order Price</label>
                                    <input
                                        className="input"
                                        name="orderPrice"
                                        value={`${order.orderPrice} XOF`}
                                        type="text"
                                        required={true}
                                    />
                                </div>

                                <div>
                                    <label htmlFor="orderEmailProduct">Order Email Product</label>
                                    <input
                                        className="input"
                                        name="orderEmailProduct"
                                        value={order.orderEmailProduct}
                                        type="email"
                                        required={true}
                                    />
                                </div>

                                <div>
                                    <label htmlFor="orderPasseWordProduct">Order Password Product</label>
                                    <input
                                        className="input"
                                        name="orderPasseWordProduct"
                                        value={order.orderPasseWordProduct}
                                        type="text"
                                        required={true}
                                    />
                                </div>

                                
                            </div>
                        </form>
                    </div>
                </div>
            {error && (
                <h1
                    style={{
                        color: "red",
                        position: "fixed",
                        left: "10%",
                        bottom: "10%",
                    }}
                >
                    {error.response.data.message}
                </h1>
            )}
        </div>
    );
}

export default ShowOrder;
