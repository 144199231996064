
import "../../Assets/Styles/Connection.css"

function SignOut() {
  const handleLogout =(e) =>{
    e.preventDefault();
    // Supprimer le token et les informations de l'utilisateur du LocalStorage
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    localStorage.removeItem('isBooleanValueAdmin');
    localStorage.removeItem('tokenExpiration');
    // toggleBooleanValue();
    window.location.href = '/connection'; 

  }
  
  return (
    <div>
      <button className="btn-sign-out" onClick={handleLogout}>
				Logout
			</button>
    </div>
  )
}

export default SignOut ;