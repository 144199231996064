import React, { useEffect, useState } from "react";
import Instance from "../../Services/Instance";
import config from "../../Services/config.js";
import SideBar from "../../Layouts/SideBar";
import "../../Assets/Styles/Subscriptions.css"; // Assurez-vous d'ajuster le chemin selon votre structure de fichiers
import "../../Assets/Styles/Products.css";
import { Link } from "react-router-dom"

import { useParams } from "react-router-dom";

function ShowSubscription() {
    const [error, setError] = useState("");
    const [subscription, setSubscription] = useState([]);
    const { id } = useParams();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const result = await Instance.get(
                    `${config.api_url}/show-subscription-id/${id}`
                );
                // console.log(result.data.subscription);
                setSubscription(result.data.subscription);
            } catch (error) {
                setError(error);
                // console.log(error);
            }
        };

        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    const validateSubManually = async () => {
        try {
            const res = await Instance.post(`${config.api_url}/update-subscription-manually/${subscription.subscriptionRef}`);
            // console.log(res);

            alert("Commande Valider manuellement avec succès");
            window.location.reload();

        } catch (error) {
            setError(error);
            // console.log(error);
        }
    };

    const formattedDate = (_date) => {
        const dateObject = new Date(_date);
        return dateObject.toLocaleDateString('fr-FR')
    }
    // console.log(subscription);
    return (
        <div className="a-new-product">
            <SideBar />
            <div className='show-elements-details'>
                <p>Statut de l'abonnement : <span id={subscription.subscriptionStatus === 'Not Validate' ? "share-not-validate" : "share-validate"}> {subscription.subscriptionStatus} </span></p>
                <p>Carégorie : <span> {subscription.CategoryName}  </span></p>
                <p>Nom du produit  : <span> {subscription.subscriptionName}  </span></p>
                {subscription.productTypeSmart && subscription.productTypeSmart === "Smart TV" && <p> Type d'abonnement NETFLIX  : <span> {subscription.productTypeSmart} </span></p>}
                <p>N° de Commande: <span> {subscription.subscriptionRef} </span></p>
                <p>Clé de la Licence  : <span> {subscription.subscriptionKey} </span></p>
                <p>Prix de l'abonnement : <span> {subscription.subscriptionPrice} </span></p>
                <p>Nombre de mois payés : <span> {subscription.subscriptionQuantity} </span></p>
                <p>Date de Commande : <span> {formattedDate(subscription.createdAt)} </span></p>
                <p> Nom de l'utilisateur : <span> {subscription.userFirstName} {subscription.userLastName} </span></p>
                <p> Email de l'utilisateur   : <span> {subscription.userEmail} </span></p>
                <p> N° de Téléphone de l'utilisateur   : <span> {subscription.userNumberPhone} </span></p>
                <p> Pays de demande   : <span> {subscription.userCountry} </span></p>

                <div className="validate">
                    <Link to={`/dashboard/subscription/edit-subscription/${id}`} className="add-new-element" id="edit-infos-sub"> Modifier </Link>
                    {subscription.subscriptionStatus && subscription.subscriptionStatus === 'Not Validate' && <button onClick={validateSubManually} className="manual-validate" id="edit-infos-sub"> Valider Manuellement la commande </button>}                </div>
            </div>
            {error && (
                <h1
                    style={{
                        color: "red",
                        position: "fixed",
                        left: "10%",
                        bottom: "10%",
                    }}
                >
                    {error.response?.data?.message || error.message}
                </h1>
            )}
        </div>
    );
}

export default ShowSubscription;
