import React, { useState, useEffect } from "react";
import Instance from "../../Services/Instance";
import config from "../../Services/config.js";
import SideBar from "../../Layouts/SideBar";
import "../../Assets/Styles/Products.css"; 
import "../../Assets/Styles/Blog.css"; 
import { useParams } from "react-router-dom";

function EditPost() {
    const [error, setError] = useState("");
    const [post, setPost] = useState({
        title: "",
        content: "",
        category: "",
    });
    const { id } = useParams();
    const { title, content, category } = post;

    const onInputChange = (e) => {
        setPost({ ...post, [e.target.name]: e.target.value });
    };


    useEffect(() => {
        const fetchData = async () => {
            try {
                const result = await Instance.get(
                    `${config.api_url}/post/${id}`
                );
                setPost(result.data.post);
            } catch (error) {
                setError(error);
                console.log(error);
            }
        };

        fetchData();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {

            await Instance.put(`${config.api_url}/update-post/${id}`, post);
            window.location = "/dashboard/blog";
        } catch (error) {
            console.error("-+-+-+-Erreur lors de la connexion:", error);
            setError(error);
        }
    };

    return (
        <div className='a-new-post'>
            <SideBar />
            <div className="parent-new-product">
                <form onSubmit={(e) => handleSubmit(e)} className="new-product custom-layout-product" encType="multipart/form-data">
                    <div className="form-display ">
                        <div>
                            <label htmlFor="title">Title</label>
                            <input
                                className="input"
                                value={title}
                                name="title"
                                type="text"
                                onChange={(e) => onInputChange(e)}
                                required={true}
                            />
                        </div>

                        <div>
                            <label htmlFor="category">Category</label>
                            <input
                                className="input"
                                value={category}
                                name="category"
                                type="text"
                                onChange={(e) => onInputChange(e)}
                                required={true}
                            />
                        </div>
                        <div id="description">
                            <label htmlFor="content">Content</label>
                            <textarea
                                className="input"
                                value={content}
                                name="content"
                                type="text"
                                onChange={(e) => onInputChange(e)}
                                required={true}
                                maxLength={500}
                            />
                        </div>
                    </div>

                    <div className="validate">
                    <button type="submit" className="add-new-element">
                        Modifier
                    </button>
                </div>                </form>
            </div>
            {error && (
                <h1 style={{ color: "red", position: "fixed", left: "10%", bottom: "10%" }}>{error.response.data.message}</h1>
            )}
        </div>
    );
}

export default EditPost;
