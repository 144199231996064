import React, { useState, useEffect } from "react";
import Instance from "../../Services/Instance";
import config from "../../Services/config.js";
import SideBar from "../../Layouts/SideBar";
import "../../Assets/Styles/Products.css";
import "../../Assets/Styles/Subscriptions.css"; // Assurez-vous d'ajuster le chemin selon votre structure de fichiers
import { useParams } from "react-router-dom";

function EditSubscription() {
    const [error, setError] = useState("");
    const [subscription, setSubscription] = useState({
        subscriptionName: "",
        subscriptionType: "",
        subscriptionQuantity: "",
        subscriptionPrice: "",
        productTypeSmart: "",
        CategoryName: "",
        userFirstName: "",
        userLastName: "",
        userEmail: "",
        userPasseWord: "",
        userNumberPhone: "",
        userCountry: "",
    });
    const { id } = useParams();

    const {
        subscriptionName, subscriptionType, subscriptionKey, subscriptionQuantity, subscriptionPrice,productTypeSmart, CategoryName, userFirstName, userLastName, userEmail, userPasseWord, userNumberPhone, userCountry, } = subscription;

    const onInputChange = (e) => {
        setSubscription({ ...subscription, [e.target.name]: e.target.value });
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const result = await Instance.get(
                    `${config.api_url}/show-subscription-id/${id}`
                );
                setSubscription(result.data.subscription);

            } catch (error) {
                setError(error);
                console.log(error);
            }
        };

        fetchData();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);
    // console.log(subscription)
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await Instance.put(
                `${config.api_url}/update-subscription/${id}`,
                subscription
            );
            window.location = "/dashboard/subscription";
        } catch (error) {
            console.error("Error:", error);
            setError(error);
        }
    };

    return (
        <div className="a-new-subscription">
            <SideBar />
            <div className="parent-new-product ">
                <form
                    onSubmit={(e) => handleSubmit(e)}
                    className="new-product "
                    encType="multipart/form-data"
                >
                    <div className="form-display ">
                        <div>
                            <label htmlFor="subscriptionName">Subscription Name</label>
                            <input
                                className="input"
                                value={subscriptionName}
                                name="subscriptionName"
                                type="text"
                                // onChange={(e) => onInputChange(e)}
                                required={true}
                            />
                        </div>
                        <div>
                            <label htmlFor="subscriptionType">Subscription Type</label>
                            <input
                                className="input"
                                value={subscriptionType}
                                name="subscriptionType"
                                type="text"
                                // onChange={(e) => onInputChange(e)}
                                required={true}
                            />
                        </div>
                        {
                            productTypeSmart  && productTypeSmart === "Smart TV" &&
                            <div>
                            <label htmlFor="productTypeSmart">Subscription Type</label>
                            <input
                                className="input"
                                value={productTypeSmart}
                                name="productTypeSmart"
                                type="text"
                                // onChange={(e) => onInputChange(e)}
                            />
                        </div>
                        }
                        <div>
                            <label htmlFor="subscriptionQuantity">Subscription Quantity</label>
                            <input
                                className="input"
                                type="number"
                                name="subscriptionQuantity"
                                value={subscriptionQuantity}
                                min={1}
                                // onChange={(e) => onInputChange(e)}
                                required
                            />
                        </div>
                        <div>
                            <label htmlFor="subscriptionPrice">Subscription Price</label>
                            <input
                                className="input"
                                type="number"
                                name="subscriptionPrice"
                                value={subscriptionPrice}
                                min={0}
                                // onChange={(e) => onInputChange(e)}
                                required
                            />
                        </div>
                        <div>
                            <label htmlFor="CategoryName">Category Name</label>
                            <input
                                className="input"
                                value={CategoryName}
                                name="CategoryName"
                                type="text"
                                // onChange={(e) => onInputChange(e)}
                                required={true}
                            />
                        </div>

                        <div>
                            <label htmlFor="userCountry">User Country</label>
                            <input
                                className="input"
                                value={userCountry}
                                name="userCountry"
                                type="text"
                                onChange={(e) => onInputChange(e)}
                                required={true}
                            />
                        </div>

                        <div>
                            <label htmlFor="userFirstName">User First Name</label>
                            <input
                                className="input"
                                value={userFirstName}
                                name="userFirstName"
                                type="text"
                                onChange={(e) => onInputChange(e)}
                                required={true}
                            />
                        </div>
                        <div>
                            <label htmlFor="userLastName">User Last Name</label>
                            <input
                                className="input"
                                value={userLastName}
                                name="userLastName"
                                type="text"
                                onChange={(e) => onInputChange(e)}
                                required={true}
                            />
                        </div>
                        <div>
                            <label htmlFor="userEmail">User Email</label>
                            <input
                                className="input"
                                value={userEmail}
                                name="userEmail"
                                type="email"
                                onChange={(e) => onInputChange(e)}
                                required={true}
                            />
                        </div>

                        <div>
                            <label htmlFor="userNumberPhone">User Number Phone</label>
                            <input
                                className="input"
                                value={userNumberPhone}
                                name="userNumberPhone"
                                type="tel"
                                onChange={(e) => onInputChange(e)}
                            />
                        </div>

                        <div id="description">
                            <label htmlFor="subscriptionKey">License</label>
                            <textarea
                                className="input"
                                value={subscriptionKey}
                                name="subscriptionKey"
                                type="text"
                                onChange={(e) => onInputChange(e)}
                                required={true}
                            />
                        </div>
                    </div>

                    <div className="validate">
                        <button type="submit" className="add-new-element">Modifier</button>
                    </div>
                </form>
            </div>
            {error && (
                <h1
                    style={{
                        color: "red",
                        position: "fixed",
                        left: "10%",
                        bottom: "10%",
                    }}
                >
                    {error.response.data.message}
                </h1>
            )}
        </div>
    );
}

export default EditSubscription;
