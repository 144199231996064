import React, { useContext } from 'react';
import { DataContext } from '../Services/DataContext';
import MainLayout from '../Layouts/MainLayout';
import SideBar from '../Layouts/SideBar';
import CustomList from '../Layouts/CustomList';
// import { useParams } from 'react-router-dom';

function Order() {

    const { orders } = useContext(DataContext);
    const barParams = { nav1: 'Nom', nav2: 'Numéro Commande', nav3: 'Categorie', nav4: 'Prix/mois', nav5 : "Quantité" };
    const titleItem = { name: "Order", title1: "Nouveau Commande", title2: "Nombre de Order" , title3 : "Order", title4 : "Order"}
    const linkItem = {  link1: "/dashboard/order/new-order", link2: "#" , link3 : "#", link4 : "#"}
    const linkList = { show : `/dashboard/order/show-order` ,edit : "/dashboard/order/edit-order"}
    // console.log(products);
    return (
        <div className='Product-layout'>
            <SideBar />
            <MainLayout 
                titleItem={titleItem}
                linkItem = {linkItem}
                numberItem ={orders.length}

            />
            <CustomList
                barParams={barParams}
                listItems={[...orders].reverse()}
                linkList = {linkList}
                key1="orderName"
                key2="orderNumber"
                key3="CategoryName"
                key4="orderPrice"
                key5="orderQuantity"
                idKey = "_id"
                urlDelete = "delete-order"

            />
        </div>
    );
}

export default Order;
