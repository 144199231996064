import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';

const Histogram = ({ data }) => {
    const chartRef = useRef(null);
    const chartInstance = useRef(null);
    

    useEffect(() => {
        if (chartInstance.current !== null) {
            chartInstance.current.destroy();
        }

        if (chartRef && chartRef.current) {
            const ctx = chartRef.current.getContext('2d');

            chartInstance.current = new Chart(ctx, {
                type: 'bar',
                data: {
                    labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'], // Labels pour chaque mois
                    datasets: [{
                        label: 'Commandes',
                        data: data.values,
                        borderWidth: 2,
                        borderColor : "#00acc1",
                        borderRadius : 5,
                        backgroundColor: "#c3e8f0", // Couleur de fond des barre
                    }]
                },
                options: {
                    scales: {
                        y: {
                            beginAtZero: true,
                            ticks: {
                                font: {
                                    size: 14,
                                    family: 'Arial'
                                }
                            }
                        },
                        x: {
                            ticks: {
                                font: {
                                    size: 12,
                                    family: 'Arial',
                                    
                                }
                            }
                        }
                    }
                }
            });
        }

        return () => {
            if (chartInstance.current !== null) {
                chartInstance.current.destroy();
            }
        };
    }, [data]);

    return (
        <div>
            <canvas ref={chartRef} />
        </div>
    );
};

export default Histogram;
