import React, { useContext } from 'react';
import { DataContext } from '../Services/DataContext';
import MainLayout from '../Layouts/MainLayout';
import SideBar from '../Layouts/SideBar';
import CustomList from '../Layouts/CustomList';
// import { useParams } from 'react-router-dom';

function ReNewal() {

    const { subscriptions } = useContext(DataContext);
    const barParams = { nav1: 'Date', nav2: 'Nom Produit', nav3: 'N° Commande', nav4: 'Prix', nav5 : "Quantité" };
    const titleItem = { name: "Renouvelement", title1: "Renouvelement", title2: "Nombre de Renouvelement"}
    const linkItem = {  link1: "#", link2: "#" , link3 : "#", link4 : "#"}
    const linkList = { show : `/dashboard/renewal/show-renewal` ,edit : "#"}
    

    const filterDuplicates = (array) => {
        // Créer un objet de hachage pour stocker les clés uniques
        const hash = {};
        // Filtrer les éléments en utilisant l'objet de hachage pour garder un seul élément par clé unique
        const filteredArray = array.filter(item => {
            if (!hash[item.subscriptionRef]) {
                hash[item.subscriptionRef] = true;
                return true;
            }
            return false;
        });
        return filteredArray;
    };
    
    const filteredArray = filterDuplicates(subscriptions);

    // const renewals = filteredArray.filter((renewal)=>{return  renewal.subscriptionRef.includes("ReNew")})
    const renewals = filteredArray.filter((renewal) => 
        renewal.subscriptionRef.includes("ReNew") || renewal.subscriptionRef.includes("_ReNew_")
    );
    

    return (
        <div className='Product-layout'>
            <SideBar />
            <MainLayout 
                titleItem={titleItem}
                linkItem = {linkItem}
                numberItem ={renewals.length}

            />
            <CustomList
                barParams={barParams}
                listItems={[...renewals].reverse()}
                linkList = {linkList}
                key1="createdAt"
                key2="subscriptionName"
                key3="subscriptionRef"
                key4="subscriptionPrice"
                key5="subscriptionQuantity"
                idKey = "_id"
                urlDelete = "delete-subscription"


            />
        </div>
    );
}

export default ReNewal;