import React from 'react';
import iconEdit from "../Assets/Imgs/Icon-edite.svg";
import iconNumbers from "../Assets/Imgs/Icon-numbers.svg"
import { Link } from 'react-router-dom';

function MainLayout(props) {
    const { titleItem, numberItem, imgItem, linkItem } = props;

    // Vérifie si titleItem est défini avant d'y accéder
    if (!titleItem) {
        return <div>Erreur: Aucun titre défini.</div>;
    }

    return (
        <div className='main-layout-dashboard'>
            <div className='title'>
                <h2> {titleItem.name}</h2>
                <p> Dashboard / {titleItem.name} </p>
            </div>

            <div className='card-dashboard'>
                {titleItem.title1 && (
                    <div className='card-main-layout-dashboard'>
                        <div className='title'>
                            <p> {titleItem.title1}</p>
                        </div>
                        <Link to={linkItem.link1}>
                            <img src={iconEdit} alt='icon' />
                        </Link>
                    </div>
                )}

                {titleItem.title2 && (
                    <div className='card-main-layout-dashboard'>
                        <div className='title'>
                            <p> {titleItem.title2}</p>
                            <h2> {numberItem} </h2>
                        </div>
                        <Link to={linkItem.link2}>
                            <img src={iconNumbers} alt='icon' />
                        </Link>
                    </div>
                )}

            </div>
        </div>
    );
}

export default MainLayout;
