import React, { useEffect, useState } from "react";
import Instance from "../../Services/Instance";
import config from "../../Services/config.js";
import SideBar from "../../Layouts/SideBar";
import "../../Assets/Styles/Products.css";
import "../../Assets/Styles/Share.css";
import { useParams } from "react-router-dom";

function ShowShare() {
    const [error, setError] = useState("");
    const [share, setShare] = useState([]);
    const { id } = useParams();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const result = await Instance.get(
                    `${config.api_url}/show-share/${id}`
                );

                // console.log(result.data.share);
                setShare(result.data.share);
            } catch (error) {
                setError(error);
                console.log(error);
            }
        };

        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    const formattedDate = (_date) => {
        const dateObject = new Date(_date);
        return dateObject.toLocaleDateString('fr-FR')
    }

    const handleValidate = async (optionShare) => {
        try {
            if (share.status === "Not Validate" && optionShare === "Decline") {
                const res = await Instance.put(`${config.api_url}/update-share/${id}`, { status: 'Decline' });
                // Mise à jour locale du state après la déclinetion 
                setShare({ ...share, status: 'Decline' });
                await Instance.post(`${config.api_url}/send-email-share`, res.data.updatedShare);
                window.location.reload();

            } else if (share.status === "Not Validate" && optionShare === "Validate") {
                const res =  await Instance.put(`${config.api_url}/update-share/${id}`, { status: 'Validate' });
                // Mise à jour locale du state après la validation réussie
                setShare({ ...share, status: 'Validate' });
                await Instance.post(`${config.api_url}/send-email-share`, res.data.updatedShare);
                window.location.reload();

            } else if (share.status === "Validate" && share.ReqPaymentShare === "Request Payment" && optionShare === "Validate") {
                const res = await Instance.put(`${config.api_url}/update-share/${id}`, { ReqPaymentShare: 'Validate Payment' });
                // Mise à jour locale du state après la validation réussie
                setShare({ ...share, status: 'Validate' });
                await Instance.post(`${config.api_url}/send-email-share`, res.data.updatedShare);
                window.location.reload();

            } else if (share.status === "Validate" && share.ReqPaymentShare === "Request Payment" && optionShare === "Decline") {
                const res = await Instance.put(`${config.api_url}/update-share/${id}`, { ReqPaymentShare: 'Decline Payment' });
                // Mise à jour locale du state après la validation réussie
                setShare({ ...share, status: 'Validate' });
                await Instance.post(`${config.api_url}/send-email-share`, res.data.updatedShare);
                window.location.reload();

            }

        } catch (error) {
            setError(error);
            console.log(error);
        }
    };
    // console.log(share);
    return (
        <div className="a-new-product">
            <SideBar />
            <div className='show-elements-details'>
                <p>Carégorie : <span> {share.CategoryName} </span></p>
                <p>Nom du produit  : <span> {share.shareName} </span></p>
                <p>Prix du partage  : <span> {share.sharePrice} F CFA</span></p>

                {share.status === "Decline" ? (
                    <p>Status du partage  : <span id="share-not-validate"> {share.status} </span></p>

                ) : share.status === "Not Validate" && share.ReqPaymentShare === "Not Request Payment" ? (
                    <p>Status du partage  : <span id="share-not-validate"> {share.status} </span></p>

                ) : share.status === "Validate" && share.ReqPaymentShare === "Not Request Payment" ? (
                    <p>Status du partage  : <span id="share-validate"> {share.status} </span></p>

                ) : share.status === "Validate" && share.ReqPaymentShare === "Request Payment" ? (
                    <p>Status du demande de payment  : <span id="share-not-validate"> {share.ReqPaymentShare} </span></p>
                ) : share.status === "Validate" && share.ReqPaymentShare === "Decline Payment" ? (
                    <div>
                        <p>Status du demande de payment  : <span id="share-not-validate"> {share.ReqPaymentShare} </span></p>
                        <p>Status du partage  : <span id="share-validate"> {share.status} </span></p>
                    </div>
                ) : (
                    <div>
                        <p>Status du demande de payment  : <span id="share-validate"> {share.ReqPaymentShare} </span></p>
                        <p>Status du partage  : <span id="share-validate"> {share.status} </span></p>
                    </div>

                )}

                <p>Email du compte de l'abonnement: <span> {share.shareEmailProduct} </span></p>
                <p>Mot de passe du compte de l'abonnement  : <span> {share.sharePasseWordProduct} </span></p>
                <p>Type d'abonnement : <span> {share.shareType} </span></p>
                <p>Nombre de profils disponibles : <span> {share.shareQuantity} </span></p>
                <p>Date de partage : <span> {formattedDate(share.updatedAt)} </span></p>
                <p>Nom de l'abonné qui a partagé l'abonnement : <span> {share.userFirstName} {share.userLastName} </span></p>
                <p> Email de l'utilisateur qui a partagé l'abonnement  : <span> {share.userEmail} </span></p>
                <p> N° de Téléphone de l'utilisateur qui a partagé l'abonnement  : <span> {share.userNumberPhone} </span></p>
                {(share.ReqPaymentShare === "Request Payment" || share.status === "Not Validate" )&&
                    <div className="validate">
                        <button type="button" className="btn-status-share validate-share" onClick={() => handleValidate("Validate")}>
                            Validate
                        </button>
                        <button type="button" className="btn-status-share decline-share" onClick={() => handleValidate("Decline")}>
                            Decline
                        </button>

                    </div>}
            </div>
            {error && (
                <h1
                    style={{
                        color: "red",
                        position: "fixed",
                        left: "10%",
                        bottom: "10%",
                    }}
                >
                    {error.response.data.message}
                </h1>
            )}
        </div>
    );
}

export default ShowShare;


