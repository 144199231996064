import React, { useState, useEffect ,useContext} from 'react';
import Instance from "../../Services/Instance";
import config from "../../Services/config.js";
import { DataContext } from '../../Services/DataContext.js';

const AddCategoryOrProduct = () => {
    const [newCategory, setNewCategory] = useState([]);
    // const [categories, setCategories] = useState([]);
    const [newCategoryName, setNewCategoryName] = useState('');
    const [selectedCategoryId, setSelectedCategoryId] = useState('');
    const [newProductName, setNewProductName] = useState('');

    const [showCategoryForm, setShowCategoryForm] = useState(false);
    const [showProductForm, setShowProductForm] = useState(false);

    const {categories} = useContext(DataContext);

    const handleAddCategory = async (e) => {
        e.preventDefault();

        try {
            const response = await Instance.post(`${config.api_url}/new-category`, { categoryName: newCategoryName });
            setNewCategory([...newCategory, response.data.category]);
            setNewCategoryName('');
            alert(" Votre catégorie a été ajouté avec succès")
            window.location.reload();

        } catch (error) {
            console.error('Error creating category:', error);
        }
    };

    const handleAddProduct = async (e) => {
        e.preventDefault();

        try {
            const response = await Instance.post(`${config.api_url}/add-productName/${selectedCategoryId}`, { productName: newProductName });
            setNewCategory(newCategory.map(category =>
                category._id === selectedCategoryId
                    ? response.data.category
                    : category
            ));
            setSelectedCategoryId('');
            setNewProductName('');
            alert(" Votre nom de produit a été ajouté avec succès")

            window.location.reload();

            window.reload()
        } catch (error) {
            console.error('Error adding product to category:', error);
        }
    };
    return (
        <div className='a-new-product'>
            <h2>Manager les  Categories et les Produits</h2>

            <div className="validate btn-add-catProd">
                <button className="add-new-element" onClick={() => setShowCategoryForm(!showCategoryForm)}>
                    {showCategoryForm ? 'Masquer' : 'Nouveau Catégory'}
                </button>
            </div>
            {showCategoryForm && (
                <div>
                    <h3>Ajouter une nouvelle Catégorie</h3>
                    <form onSubmit={handleAddCategory} className="new-product content-manager-add-catProd" >
                        <div className="manager-add-catProd ">
                            <input
                                type="text"
                                placeholder="Nom de la Catégorie"
                                value={newCategoryName}
                                onChange={(e) => setNewCategoryName(e.target.value)}
                                required
                            />
                            <div className="validate">
                                <button type="submit" className="add-new-element" > Ajouter</button>
                            </div>
                        </div>

                    </form>
                </div>
            )}


            <div className="validate btn-add-catProd">
                <button className="add-new-element" onClick={() => setShowProductForm(!showProductForm)}>
                    {showProductForm ? 'Masquer' : 'Nouveau type de produit'}
                </button>
            </div>
            {showProductForm &&(
                <div>
                    <h3>Ajouter un nouveau type de produit</h3>
                    <form onSubmit={handleAddProduct} className="new-product content-manager-add-catProd">
                        <div className="manager-add-catProd ">
                            <select
                                value={selectedCategoryId}
                                onChange={(e) => setSelectedCategoryId(e.target.value)}
                                required
                            >
                                <option value="" disabled>Select Category</option>
                                {categories && categories.map(category => (
                                    <option key={category._id} value={category._id}>{category.categoryName}</option>
                                ))}
                            </select>
                            <input
                                type="text"
                                placeholder="Nom du produit"
                                value={newProductName}
                                onChange={(e) => setNewProductName(e.target.value)}
                                required
                            />

                        </div>

                        <div className="validate">
                        <button type="submit" className="add-new-element" > Ajouter</button>
                    </div>

                    </form>
                </div>
            )}
        </div>
    );
};

export default AddCategoryOrProduct;
