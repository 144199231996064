import React, { useState, useEffect } from 'react';
import SideBar from '../../Layouts/SideBar';
import Instance from '../../Services/Instance';
import config from '../../Services/config';
import { useParams } from 'react-router-dom';

function EditShare() {
  const [error, setError] = useState('');
  const [share, setShare] = useState({
    shareDate: '',
    shareName: '',
    status: '',
    shareType: '',
    shareQuantity: '',
    sharePrice: '',
    shareImg: '',
    shareEmailProduct: '',
    sharePasseWordProduct: '',
    CategoryName: '',

  });

  const { id } = useParams();

  const fetchData = async () => {
    try {
      const result = await Instance.get(`${config.api_url}/show-share/${id}`);
      // console.log(result.data);
      setShare(result.data.share);
    } catch (error) {
      setError('Erreur lors de la récupération de cette action');
      console.log(error);
    }
  };
  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await Instance.put(`${config.api_url}/update-share/${id}`, share);
      share.shareStatus=== "Request Payment" ? window.location = '/dashboard/request-payment' : window.location = '/dashboard/share';
    } catch (error) {
      console.error('Erreur lors de la mise à jour de l\'action :', error);
      setError(error);
    }
  };

  const handleInputChange = (e) => {
    setShare({ ...share, [e.target.name]: e.target.value });
  };

  return (
    <div className="a-new-product">
      <SideBar />
      <div className="parent-new-product">
        <form onSubmit={(e) => handleSubmit(e)} className="new-product custom-layout-product">
          <div className="form-display ">
            <div>
              <label htmlFor="shareName">Share Name</label>
              <input
                className="input"
                value={share.shareName}
                name="shareName"
                type="text"
                onChange={(e) => handleInputChange(e)}
                required={true}
              />
            </div>
            <div>
              <label htmlFor="status">Status</label>
              <input
                className="input"
                value={share.status}
                name="status"
                type="text"
                onChange={(e) => handleInputChange(e)}
                required={true}
              />
            </div>
            <div>
              <label htmlFor="shareType">Share Type</label>
              <input
                className="input"
                value={share.shareType}
                name="shareType"
                type="text"
                onChange={(e) => handleInputChange(e)}
                required={true}
              />
            </div>
            <div>
              <label htmlFor="shareQuantity">Share Quantity</label>
              <input
                className="input"
                value={share.shareQuantity}
                name="shareQuantity"
                type="text"
                onChange={(e) => handleInputChange(e)}
                required={true}
              />
            </div>
            <div>
              <label htmlFor="sharePrice">Share Price</label>
              <input
                className="input"
                value={share.sharePrice}
                name="sharePrice"
                type="text"
                onChange={(e) => handleInputChange(e)}
                required={true}
              />
            </div>
            <div>
              <label htmlFor="shareEmailProduct">Product Email</label>
              <input
                className="input"
                value={share.shareEmailProduct}
                name="shareEmailProduct"
                type="text"
                onChange={(e) => handleInputChange(e)}
                required={true}
              />
            </div>
            <div>
              <label htmlFor="sharePasseWordProduct">Product Password</label>
              <input
                className="input"
                value={share.sharePasseWordProduct}
                name="sharePasseWordProduct"
                type="text"
                onChange={(e) => handleInputChange(e)}
                required={true}
              />
            </div>
            <div>
              <label htmlFor="CategoryName">Category Name</label>
              <input
                className="input"
                value={share.CategoryName}
                name="CategoryName"
                type="text"
                onChange={(e) => handleInputChange(e)}
                required={true}
              />
            </div>

            <div className="validate">
              <button type="submit" className="add-new-element">
                Modifier
              </button>
            </div>
          </div>
        </form>
      </div>
      {error && (
        <h1 style={{ color: 'red', position: 'fixed', left: '10%', bottom: '10%' }}>
          {error.response ? error.response.data.message : 'Une erreur est survenue'}
        </h1>
      )}
    </div>
  );
}

export default EditShare;
