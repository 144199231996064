import React, { useState, useEffect } from "react";
import Instance from "../../Services/Instance";
import config from "../../Services/config.js";
import SideBar from "../../Layouts/SideBar";
import "../../Assets/Styles/Products.css";
import { useParams } from "react-router-dom";

function EditProduct() {
    const [error, setError] = useState("");
    const [product, setProduct] = useState({
        productCategory: "",
        productName: "",
        productType: "",
        productDescription: "",
        productPrice: "",
        productPrice2: "",
        productAttention: "",
        productInStock: "",
        faq: [{ question: "", answer: "" }] // Initial state with one FAQ
    });
    const { id } = useParams()

    const { productCategory, productName, productType, productDescription, productPrice, productPrice2, productAttention, productInStock, faq } = product;

    // const onInputChange = (e) => {
    //     setProduct({ ...product, [e.target.name]: e.target.value });
    // };

    const onInputChange = (e) => {
        const { name, value } = e.target;
        setProduct((product) => ({
            ...product, [name] : value,
        }));
    };

    const onImageChange = (e) => {
        const selectedImage = e.target.files[0];

        // Vérifiez si une image est sélectionnée
        if (selectedImage) {
            // Mettez à jour l'état avec l'URL ou le chemin de l'image
            setProduct({ ...product, productImage: URL.createObjectURL(selectedImage) });
        } else {
            // Si aucune image n'est sélectionnée, assurez-vous de réinitialiser l'état
            setProduct({ ...product, productImage: "" });
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const result = await Instance.get(
                    `${config.api_url}/show-product/${id}`
                );
                setProduct(result.data.product);
            } catch (error) {
                setError(error);
                console.log(error);
            }
        };

        fetchData();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    const handleFaqChange = (index, fieldName, value) => {
        const updatedFaqs = [...product.faq]; // Faites une copie de la liste des FAQs
        updatedFaqs[index][fieldName] = value; // Mettez à jour le champ spécifié de la FAQ
        setProduct({ ...product, faq: updatedFaqs }); // Mettez à jour l'état avec la nouvelle liste de FAQs
    };

    const addFaq = () => {
        setProduct({ ...product, faq: [...faq, { question: "", answer: "" }] });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await Instance.put(`${config.api_url}/update-product/${id}`, product);
            window.location = "/dashboard/product";
        } catch (error) {
            console.error("-+-+-+-Erreur lors de la connexion:", error);
            setError(error);
        }
    };

    return (
        <div className='a-new-product'>
            <SideBar />
            <div className="parent-new-product">
                <form onSubmit={(e) => handleSubmit(e)} className="new-product custom-layout-product" encType="multipart/form-data">
                    <div className="form-display ">
                        <div>
                            <label htmlFor="productCategory">Category</label>
                            <input
                                className="input"
                                value={productCategory}
                                name="productCategory"
                                type="text"
                                onChange={(e) => onInputChange(e)}
                                required={true}
                            />
                        </div>
                        <div>
                            <label htmlFor="productName">productName</label>
                            <input
                                className="input"
                                value={productName}
                                name="productName"
                                type="text"
                                onChange={(e) => onInputChange(e)}
                                required={true}
                            />
                        </div>

                        <div>
                            <label htmlFor="productType">productType</label>
                            <input
                                className="input"
                                value={productType}
                                name="productType"
                                type="text"
                                onChange={(e) => onInputChange(e)}
                                required={true}
                            />
                        </div>

                        <div>
                            <label htmlFor="productImage">productImage</label>
                            <input
                                className="input"
                                type="file"
                                name="productImage"
                                onChange={(e) => onImageChange(e)}
                            />
                        </div>

                        <div>
                            <label htmlFor="productPrice">productPrice</label>
                            <input
                                className="input"
                                value={productPrice}
                                name="productPrice"
                                type="text"
                                onChange={(e) => onInputChange(e)}
                                required={true}
                            />
                        </div>
                        {productName.toLocaleLowerCase().includes("netflix") &&
                            (<div>
                                <label htmlFor="productPrice2">Prix Smart TV </label>
                                <input
                                    className="input"
                                    value={productPrice2}
                                    name="productPrice2"
                                    type="text"
                                    onChange={onInputChange}
                                />
                            </div>)
                        }
                        <div>
                            <label>product In Stock:</label>
                            <select
                                value={productInStock}
                                name="productInStock"
                                onChange={onInputChange}
                            >
                                <option value="">Select a Status</option>
                                <option value="Out of Stock">Out of Stock</option>
                                <option value='Available'>Available</option>
                            </select>
                        </div>

                        <div id="description">
                            <label htmlFor="productDescription">Product Description</label>
                            <textarea
                                className="input"
                                value={productDescription}
                                name="productDescription"
                                onChange={onInputChange}
                                required
                            />
                        </div>
                        <div id="description">
                            <label htmlFor="productDescription">Text d'avertisement</label>
                            <textarea
                                className="input"
                                value={productAttention}
                                name="productAttention"
                                onChange={onInputChange}
                            />
                        </div>
                    </div>

                    <div className="faq-new-product">
                        <h1>FAQs</h1>

                        {faq.map((faq_, index) => (
                            <div key={index} className="faq-content">
                                <h4>{`Faq ${index + 1}`}</h4>
                                <div>
                                    <label htmlFor={`question-${index}`}>{`Question ${index + 1}:`}</label>
                                    <input
                                        type="text"
                                        id={`question-${index}`}
                                        value={faq_.question}
                                        onChange={(e) => handleFaqChange(index, 'question', e.target.value)}
                                    />
                                </div>
                                <div id="description">
                                    <label htmlFor={`answer-${index}`}>{`Réponse ${index + 1}:`}</label>
                                    <textarea
                                        id={`answer-${index}`}
                                        value={faq_.answer}
                                        onChange={(e) => handleFaqChange(index, 'answer', e.target.value)}
                                    />
                                </div>
                            </div>
                        ))}

                        <div>
                            <button className="more-faq" type="button" onClick={addFaq}> + PLus de FAQ</button>
                        </div>
                    </div>

                    <div className="validate">
                        <button type="submit" className="add-new-element">Modifier</button>
                    </div>
                </form>
            </div>
            {error && (
                <h1 style={{ color: "red", position: "fixed", left: "10%", bottom: "10%" }}>{error.response.data.message}</h1>
            )}
        </div>
    );
}

export default EditProduct;
