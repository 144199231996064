import React, {useEffect, useContext } from 'react';
import { DataContext } from '../Services/DataContext'; import SideBar from '../Layouts/SideBar'
import iconEdit from "../Assets/Imgs/Icon-edite.svg";
import iconUsers from "../Assets/Imgs/Icon-users.svg"
import iconProducts from "../Assets/Imgs/Icon-nbrs.png"
import Histogram from '../Components/Dashboard/Histogram';

function Dashboard() {

  const { subscriptions, products, licenses, shares } = useContext(DataContext);


  //FOnction pour  calculer les stats des mois pour les commandes
  function getMonthlyStats(subscriptions) {
    const monthlyBalances = new Array(12).fill(0); // Tableau pour les soldes mensuels
    const monthlyOrderCounts = new Array(12).fill(0); // Tableau pour le nombre de commandes mensuelles

    subscriptions.forEach(item => {
      const subscriptionDate = new Date(item.subscriptionDate);
      const month = subscriptionDate.getMonth(); // Obtenez le mois (0 pour janvier, 11 pour décembre)

      if (item.subscriptionStatus === "Validate") {
        monthlyBalances[month] += item.subscriptionPrice; // Ajoutez le prix de la souscription au solde du mois correspondant
        monthlyOrderCounts[month]++; // Incrémentez le compteur de commandes du mois correspondant
      }
    });

    return {
      monthlyBalances,
      monthlyOrderCounts
    };
  }

  //Date en cours
  let date = new Date()
  const currentDate = date.toLocaleDateString('fr-FR')

  //Satistiques des commandes
  const monthlyStats = getMonthlyStats(subscriptions)
  //Nombres de commandes du mois en cours
  let numberOfOrders = monthlyStats.monthlyOrderCounts[date.getMonth()]
  //Solde des commandes  du mois en cours
  let solde = monthlyStats.monthlyBalances[date.getMonth()]

  const _data = {
    values: monthlyStats.monthlyOrderCounts
  };

  const data = {
    values: monthlyStats.monthlyBalances
  };

  // Vérifie l'expiration du token lors du chargement initial du composant
  useEffect(() => {
    checkTokenExpiration();
  }, []);

  // Fonction pour vérifier l'expiration du token
  const checkTokenExpiration = () => {
    const tokenExpiration = localStorage.getItem('tokenExpiration');
    if (tokenExpiration) {
      const expirationTime = parseInt(tokenExpiration);
      const currentTime = new Date().getTime();
      if (currentTime > expirationTime) {
        // Supprimer le token et déconnecter l'utilisateur
        localStorage.removeItem('token');
        localStorage.removeItem('tokenExpiration');
        localStorage.removeItem('user');
        localStorage.removeItem('isBooleanValueAdmin');

        window.location="/connection"
      }
    }
  };

  return (
    <div className='dashboard'>
      <SideBar />
      <div className='dashboard-home'>
        <div className='main-layout-dashboard'>
          <div className='title'>
            {/*<h2> {titleItem.name}</h2>*/}
            <h2> Dashboard</h2>
            <p> Dashboard / home </p>
          </div>

          <div className='card-dashboard'>
            <div className='card-main-layout-dashboard'>
              <div className='title'>
                <h3> Product</h3>
                <h2> {products.length} </h2>
              </div>
              <img src={iconProducts} alt='icon' />
            </div>

            <div className='card-main-layout-dashboard'>
              <div className='title'>
                <h3> Licenses</h3>
                <h2> {licenses.length}</h2>
              </div>
              <img src={iconEdit} alt='icon' />
            </div>

            <div className='card-main-layout-dashboard'>
              <div className='title'>
                <h3> Partages</h3>
                <h2> {shares.length}</h2>
              </div>
              <img src={iconUsers} alt='icon' />
            </div>

            <div className='card-main-layout-dashboard'>
              <div className='title'>
                <h3> Commandes</h3>
                <h2> {numberOfOrders} </h2>
              </div>
              <img src={iconProducts} alt='icon' />
            </div>

          </div>
        </div>
        <div className='graphs' >
          <p>Date : <span>{currentDate}</span></p>
          <div className='graphs-details'>
            <div className='histogram'>
              <p>Commandes :<span> {numberOfOrders}</span></p>
              <Histogram data={_data} />
            </div>
            <div className='courbe'>
              <p>Solde : <span> {`${solde} XOF`}</span> </p>
              <Histogram data={data} />
            </div>

          </div>
        </div>
      </div>

    </div>
  )
}

export default Dashboard