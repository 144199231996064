import React from 'react'
import SignIn from '../Components/Connection/SignIn'

function Connection() {
  return (
    <div>
      <SignIn />
    </div>
  )
}

export default Connection