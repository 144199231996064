import React, { useContext } from 'react';
import { DataContext } from '../Services/DataContext';
import MainLayout from '../Layouts/MainLayout';
import SideBar from '../Layouts/SideBar';
import CustomList from '../Layouts/CustomList';
import "../Assets/Styles/Dasboard.css"
// import { useParams } from 'react-router-dom';

function Products() {

    const { products } = useContext(DataContext);
    const barParams = { nav1: 'Nom', nav2: 'Categorie', nav3: 'Prix/mois' };
    const titleItem = { name: "Products", title1: "Nouveau Produit", title2: "Nombre de produit", title3: "Products", title4: "Products" }
    const linkItem = { link1: "/dashboard/product/new-product", link2: "#", link3: "#", link4: "#" }
    const linkList = { show: `/dashboard/product/show-product`, edit: "/dashboard/product/edit-product" }
    // console.log("+++++++++",products);
    return (
        <div className='Product-layout'>
            <SideBar />
            <MainLayout
                titleItem={titleItem}
                linkItem={linkItem}
                numberItem={products.length}

            />

            <CustomList
                barParams={barParams}
                listItems={[...products].reverse()}
                linkList={linkList}
                key1="productName"
                key2="productCategory"
                key3="productPrice"
                idKey="_id"
                urlDelete="delete-product"

            />
        </div>
    );
}

export default Products;
