import React, { useState } from "react";
import Instance from "../../Services/Instance";
import config from "../../Services/config.js";
import SideBar from "../../Layouts/SideBar";
import "../../Assets/Styles/Products.css";
import "../../Assets/Styles/Blog.css";

function NewPost() {
    const [error, setError] = useState("");
    const [newPost, setNewPost] = useState({
        title: "",
        content: "",
        category: "",
        productImage: null, // Change to null for File
    });

    const { title, content, category, productImage } = newPost;

    const onInputChange = (e) => {
        setNewPost({ ...newPost, [e.target.name]: e.target.value });
    };

    const onImageChange = (e) => {
        setNewPost({ ...newPost, productImage: e.target.files[0] });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const formData = new FormData();
            formData.append('title', title);
            formData.append('content', content);
            formData.append('category', category);
            formData.append('productImage', productImage);
            // console.log(formData);
            await Instance.post(`${config.api_url}/new-post`, formData);
            window.location = "/dashboard/blog";
        } catch (error) {
            console.error("-+-+-+-Erreur lors de la creation de post:", error);
            setError(error);
        }
    };

    return (
        <div className='a-new-product'>
            <SideBar />
            <div className="parent-new-product">
                <form onSubmit={(e) => handleSubmit(e)} className="new-post custom-layout-product" encType="multipart/form-data">
                    <div className="form-display ">
                        <div>
                            <label htmlFor="title">Title</label>
                            <input
                                className="input"
                                value={title}
                                name="title"
                                type="text"
                                onChange={(e) => onInputChange(e)}
                                required={true}
                            />
                        </div>


                        <div>
                            <label htmlFor="category">Category</label>
                            <input
                                className="input"
                                value={category}
                                name="category"
                                type="text"
                                onChange={(e) => onInputChange(e)}
                                required={true}
                            />
                        </div>

                        <div>
                            <label htmlFor="image">Image</label>
                            <input
                                className="input"
                                type="file"
                                name="productImage"
                                onChange={(e) => onImageChange(e)}
                                required
                            />
                        </div>

                        <div id="description">
                            <label htmlFor="content">Content</label>
                            <textarea
                                className="input"
                                value={content}
                                name="content"
                                type="text"
                                onChange={(e) => onInputChange(e)}
                                required={true}
                            />
                        </div>
                    </div>

                    <div className="validate">
                        <button type="submit" className="add-new-element">Ajouter</button>
                    </div>
                </form>
            </div>
            {error && (
                <h1 style={{ color: "red", position: "fixed", left: "10%", bottom: "10%" }}>{error.response.data.message}</h1>
            )}
        </div>
    );
}

export default NewPost;
