import React, { useEffect, useState } from 'react'
import SideBar from '../../Layouts/SideBar'
import { useParams } from 'react-router-dom'
import Instance from '../../Services/Instance'
import config from "../../Services/config"

function ShowUser() {
    const [user, setUser] = useState({})

    const { id } = useParams()

    const fetchData = async () => {
        try {
            const result = await Instance.get(`${config.api_url}/show-user/${id}`)
            setUser(result.data.user);
        } catch (error) {
            console.log("Erreur lors de la récupération de cet utilisateur", error);
        }
    }

    useEffect(() => {
        fetchData();
    }, [id])

    return (
        <div className='show-user'>
            <div>
                <SideBar />
            </div>
            <div className='show-elements-details'>
                <p>Prénom : <span>{user.firstName}</span></p>
                <p>Nom : <span>{user.lastName}</span></p>
                <p>Numéro de téléphone : <span>{user.numberPhone}</span></p>
                <p>Email : <span>{user.email}</span></p>
            </div>
        </div>
    )
}

export default ShowUser
