import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import "../Assets/Styles/Dasboard.css";
import logo from "../Assets/Imgs/senpremiumlogo2 3.svg";
import SignOut from '../Components/Connection/SignOut';

function SideBar() {
    const location = useLocation();

    return (
        <div className='parent-side-bar'>
            <div className='side-bar'>
                <div className='logo-side-bar'>
                    <Link to="/dashboard" >
                        <img src={logo} alt='' />
                    </Link>
                </div>
                <div className='navigation'>
                    <NavLink to="/dashboard">Home</NavLink>
                    <NavLink to="/dashboard/product">Produits</NavLink>
                    <NavLink to="/dashboard/licenses">Licenses</NavLink>
                    <NavLink to="/dashboard/share">Partages</NavLink>
                    <NavLink to="/dashboard/request-payment">Demandes de Payments</NavLink>
                    <NavLink to="/dashboard/subscription">Commandes</NavLink>
                    <NavLink to="/dashboard/renewal">Renouvelement</NavLink>
                    <NavLink to="/dashboard/blog">Blog</NavLink>
                    <NavLink to="/dashboard/users">Utilisateurs</NavLink>
                    <NavLink to="https://help-center.senpremium.com/backoffice" target="_blank">Centre d'aide</NavLink>
                    <SignOut />
                </div>
            </div>
        </div>
    );
}

function NavLink({ to, children }) {
    const location = useLocation();
    const isActive = location.pathname === to;

    return (
        <Link to={to} className={`navigation-link ${isActive ? 'active' : ''}`}>
            {children}
        </Link>
    );
}

export default SideBar;
