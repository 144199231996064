import React, { useState, useEffect } from 'react';
import SideBar from '../../Layouts/SideBar';
import Instance from '../../Services/Instance';
import config from '../../Services/config';
import { useParams } from 'react-router-dom';

function EditUser() {
    const [error, setError] = useState('');
    const [user, setUser] = useState({
        firstName: '',
        lastName: '',
        numberPhone: '',
        email: '',
    });

    const { id } = useParams();

    const fetchData = async () => {
        try {
            const result = await Instance.get(`${config.api_url}/show-user/${id}`);
            setUser(result.data.user);
        } catch (error) {
            setError('Erreur lors de la récupération de cet utilisateur');
            console.log(error);
        }
    };

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await Instance.put(`${config.api_url}/update-user/${id}`, user);
            window.location = '/dashboard/users';
        } catch (error) {
            console.error('Erreur lors de la mise à jour de l\'utilisateur :', error);
            setError(error);
        }
    };

    const handleInputChange = (e) => {
        setUser({ ...user, [e.target.name]: e.target.value });
    };

    return (
        <div className="a-new-product">
            <SideBar />
            <div className="parent-new-product">
                <form onSubmit={(e) => handleSubmit(e)} className="new-product custom-layout-product">
                    <div className="form-display ">
                        <div>
                            <label htmlFor="firstName">First Name</label>
                            <input
                                className="input"
                                value={user.firstName}
                                name="firstName"
                                type="text"
                                onChange={(e) => handleInputChange(e)}
                                required={true}
                            />
                        </div>
                        <div>
                            <label htmlFor="lastName">Last Name</label>
                            <input
                                className="input"
                                value={user.lastName}
                                name="lastName"
                                type="text"
                                onChange={(e) => handleInputChange(e)}
                                required={true}
                            />
                        </div>
                        <div>
                            <label htmlFor="numberPhone">Number Phone</label>
                            <input
                                className="input"
                                value={user.numberPhone}
                                name="numberPhone"
                                type="text"
                                onChange={(e) => handleInputChange(e)}
                                required={true}
                            />
                        </div>
                        <div>
                            <label htmlFor="email">Email</label>
                            <input
                                className="input"
                                value={user.email}
                                name="email"
                                type="email"
                                onChange={(e) => handleInputChange(e)}
                                required={true}
                            />
                        </div>
                    </div>
                    <div className="validate">
                        <button type="submit" className="add-new-element">
                            Modifier
                        </button>
                    </div>
                </form>
            </div>
            {error && (
                <h1 style={{ color: 'red', position: 'fixed', left: '10%', bottom: '10%' }}>
                    {error.response ? error.response.data.message : 'Une erreur est survenue'}
                </h1>
            )}
        </div>
    );
}

export default EditUser;
