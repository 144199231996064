import React, { useState } from "react";
import Instance from "../../Services/Instance";
import config from "../../Services/config.js";
import SideBar from "../../Layouts/SideBar";
import "../../Assets/Styles/Order.css"; 
import "../../Assets/Styles/Products.css"; 


function NewOrder() {
    const [error, setError] = useState("");
    const [newOrder, setNewOrder] = useState({
        orderNumber: "",
        orderDate: new Date(),
        orderName: "",
        orderType: "",
        orderQuantity: "",
        orderPrice: "",
        orderEmailProduct: "",
        orderPasseWordProduct: "",
        CategoryName: "",
    });

    const { orderNumber, orderName, orderType, orderQuantity, orderPrice, orderEmailProduct, orderPasseWordProduct, CategoryName } = newOrder;

    const onInputChange = (e) => {
        setNewOrder({ ...newOrder, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await Instance.post(`${config.api_url}/new-order`, newOrder);
            window.location = "/dashboard/order";
        } catch (error) {
            console.error("-+-+-+-Erreur lors de la connexion:", error);
            setError(error);
            // console.log("+++++++");
        }
    };

    return (
        <div className='a-new-order'>
            <SideBar />
            <div className="parent-new-product">
                <form onSubmit={(e) => handleSubmit(e)} className="new-product custom-layout-product">
                    <div className="form-display ">
                        <div>
                            <label htmlFor="orderNumber">Order Number</label>
                            <input
                                className="input"
                                value={orderNumber}
                                name="orderNumber"
                                type="number"
                                onChange={(e) => onInputChange(e)}
                                required={true}
                            />
                        </div>
                        { /*<div>
                            <label htmlFor="orderDate">Order Date</label>
                            <input
                                className="input"
                                value={orderDate}
                                name="orderDate"
                                type="date"
                                onChange={(e) => onInputChange(e)}
                                required={true}
                            />
                        </div>*/}

                        <div>
                            <label htmlFor="CategoryName">Category Name</label>
                            <input
                                className="input"
                                value={CategoryName}
                                name="CategoryName"
                                type="text"
                                onChange={(e) => onInputChange(e)}
                                required={true}
                            />
                        </div>

                        <div>
                            <label htmlFor="orderName">Order Name</label>
                            <input
                                className="input"
                                value={orderName}
                                name="orderName"
                                type="text"
                                onChange={(e) => onInputChange(e)}
                                required={true}
                            />
                        </div>

                        <div>
                            <label htmlFor="orderType">Order Type</label>
                            <input
                                className="input"
                                value={orderType}
                                name="orderType"
                                type="text"
                                onChange={(e) => onInputChange(e)}
                                required={true}
                            />
                        </div>

                        <div>
                            <label htmlFor="orderQuantity">Order Quantity</label>
                            <input
                                className="input"
                                type="number"
                                name="orderQuantity"
                                value={orderQuantity}
                                min={1}
                                onChange={(e) => onInputChange(e)}
                                required
                            />
                        </div>

                        <div>
                            <label htmlFor="orderPrice">Order Price</label>
                            <input
                                className="input"
                                value={orderPrice}
                                name="orderPrice"
                                type="number"
                                onChange={(e) => onInputChange(e)}
                                required={true}
                            />
                        </div>

                        <div>
                            <label htmlFor="orderEmailProduct">Order Email Product</label>
                            <input
                                className="input"
                                value={orderEmailProduct}
                                name="orderEmailProduct"
                                type="email"
                                onChange={(e) => onInputChange(e)}
                                required={true}
                            />
                        </div>

                        <div>
                            <label htmlFor="orderPasseWordProduct">Order Password Product</label>
                            <input
                                className="input"
                                value={orderPasseWordProduct}
                                name="orderPasseWordProduct"
                                type="text"
                                onChange={(e) => onInputChange(e)}
                                required={true}
                            />
                        </div>

                        
                    </div>

                    <div className="validate">
                        <button type="submit" className="add-new-element">Ajouter</button>
                    </div>
                </form>
            </div>
            {error && (
                <h1 style={{ color: "red", position: "fixed", left: "10%", bottom: "10%" }}>{error.response.data.message}</h1>
            )}
        </div>
    );
}

export default NewOrder;
