import React, { useEffect, useState } from 'react'
import SideBar from '../../Layouts/SideBar'
import { useParams } from 'react-router-dom'
import Instance from '../../Services/Instance'
import config from "../../Services/config"

function ShowPost() {
  const [post, setPost] = useState([])

  const { id } = useParams()

  const fetchData = async () => {
      try {
          const result = await Instance.get(`${config.api_url}/post/${id}`)
              // console.log(result.data.license);
              setPost(result.data.post);
      } catch (error) {
          console.log("Erreur lors de la recupération de cette license", error);
      }
  }

  useEffect(() => {
      fetchData();
  }, [id])

  return (
    <div>
      <SideBar />
      <div className='show-elements-details'>
                <p>Catégorie : <span> {post.category} </span></p>
                <p>Titre : <span> {post.title} </span></p>
                <p>Contenu post  : <span> {post.content} </span></p>
                
            </div>
    </div>
  )
}

export default ShowPost