import React, { useContext } from 'react';
import { DataContext } from '../Services/DataContext';
import MainLayout from '../Layouts/MainLayout';
import SideBar from '../Layouts/SideBar';
import CustomList from '../Layouts/CustomList';
// import { useParams } from 'react-router-dom';

function Share() {

    const { shares } = useContext(DataContext);
    //Filter just the shares and no the request payment 
    let justShares = [];

    justShares = shares.filter((share) => share.shareStatus !== "Request Payment")

    const barParams = { nav1: 'Nom', nav2: 'Categorie', nav3: 'Type', nav4: 'Quantité', nav5: "Status" };
    const titleItem = { name: "Partages", title1: "Partages", title2: "Nombre de Partages", title3: "Partages", title4: "Partages" }
    const linkItem = { link1: "#", link2: "#", link3: "#", link4: "#" }
    const linkList = { show: `/dashboard/share/show-share`, edit: "/dashboard/share/edit-share" }


    return (
        <div className='Product-layout'>
            <SideBar />
            <MainLayout
                titleItem={titleItem}
                linkItem={linkItem}
                numberItem={shares.length}

            />
            <CustomList
                barParams={barParams}
                listItems={[...justShares].reverse()}
                linkList={linkList}
                key1="shareName"
                key2="CategoryName"
                key3="shareType"
                key4="shareQuantity"
                key5="status"
                idKey="_id"
                urlDelete="delete-share"


            />
        </div>
    );
}

export default Share;