import React, { useState, useEffect } from 'react';
import SideBar from '../../Layouts/SideBar';
import Instance from '../../Services/Instance';
import config from '../../Services/config';
import { useParams } from 'react-router-dom';

function EditLicences() {
    const [error, setError] = useState('');
    const [license, setLicense] = useState({
        key: '',
        name: '',
        productTypeSmart:'',
        number: '',
        orderNumber: '',
        policy: '',
        productCategory: '',
        productName: '',
        status: '',
        userEmail: '',
        userName: '',
        userNumberPhone: '',
    });

    const { id } = useParams();

    const fetchData = async () => {
        try {
            const result = await Instance.get(`${config.api_url}/show-license/${id}`);
            // console.log(result.data);
            setLicense(result.data.license);
        } catch (error) {
            setError('Erreur lors de la récupération de cette licence');
            console.log(error);
        }
    };

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await Instance.put(`${config.api_url}/update-license/${id}`, license);
            window.location = '/dashboard/licenses';
        } catch (error) {
            console.error('Erreur lors de la mise à jour de la licence :', error);
            setError(error);
        }
    };

    const handleInputChange = (e) => {
        setLicense({ ...license, [e.target.name]: e.target.value });
    };

    return (
        <div className="a-new-product">
            <SideBar />
            <div className="parent-new-product">
                <form onSubmit={(e) => handleSubmit(e)} className="new-product custom-layout-product">
                    <div className="form-display ">
                        <div>
                            <label htmlFor="key">Key</label>
                            <input
                                className="input"
                                value={license.key}
                                name="key"
                                type="text"
                                onChange={(e) => handleInputChange(e)}
                                required={true}
                            />
                        </div>
                        <div>
                            <label htmlFor="name">Name</label>
                            <input
                                className="input"
                                value={license.name}
                                name="name"
                                type="text"
                                onChange={(e) => handleInputChange(e)}
                                required={true}
                            />
                        </div>

                        {license.productTypeSmart && <div>
                            <label htmlFor="name">Name</label>
                            <input
                                className="input"
                                value={license.productTypeSmart}
                                name="productTypeSmart"
                                type="text"
                                onChange={(e) => handleInputChange(e)}
                            />
                        </div>}

                        <div>
                            <label htmlFor="number">Number</label>
                            <input
                                className="input"
                                value={license.number}
                                name="number"
                                type="text"
                                onChange={(e) => handleInputChange(e)}
                                required={true}
                            />
                        </div>
                        
                        <div>
                            <label htmlFor="policy">Policy</label>
                            <input
                                className="input"
                                value={license.policy}
                                name="policy"
                                type="text"
                                onChange={(e) => handleInputChange(e)}
                                required={true}
                            />
                        </div>
                        <div>
                            <label htmlFor="productCategory">Product Category</label>
                            <input
                                className="input"
                                value={license.productCategory}
                                name="productCategory"
                                type="text"
                                onChange={(e) => handleInputChange(e)}
                                required={true}
                            />
                        </div>
                        <div>
                            <label htmlFor="productName">Product Name</label>
                            <input
                                className="input"
                                value={license.productName}
                                name="productName"
                                type="text"
                                onChange={(e) => handleInputChange(e)}
                                required={true}
                            />
                        </div>
                        <div>
                            <label htmlFor="status">Status</label>
                            <input
                                className="input"
                                value={license.status}
                                name="status"
                                type="text"
                                onChange={(e) => handleInputChange(e)}
                                required={true}
                            />
                        </div>
                        
                        
                    </div>
                    <div className="validate">
                        <button type="submit" className="add-new-element">
                            Modifier
                        </button>
                    </div>
                </form>
            </div>
            {error && (
                <h1 style={{ color: 'red', position: 'fixed', left: '10%', bottom: '10%' }}>
                    {error.response ? error.response.data.message : 'Une erreur est survenue'}
                </h1>
            )}
        </div>
    );
}

export default EditLicences;
