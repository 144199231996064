import React, { useEffect, useState } from 'react'
import SideBar from '../../Layouts/SideBar'
import { useParams } from 'react-router-dom'
import Instance from '../../Services/Instance'
import config from "../../Services/config"

function ShowLicense() {
    const [license, setLicense] = useState([])

    
    const convertChar = (emailString) => {
        if (emailString) {
            const index = emailString.indexOf("undefined") + "undefined".length + 2; // Trouver l'index du premier "undefined" et ajouter sa longueur
            if (index > 1) {
                const extractedString = emailString.substring(index);
                return extractedString;
            }
        }
        return emailString;
    }
    


    const { id } = useParams()
    const formattedDate = (_date) => {
        const dateObject = new Date(_date);
        return dateObject.toLocaleDateString('fr-FR')
    }

    const fetchData = async () => {
        try {
            const result = await Instance.get(`${config.api_url}/show-license/${id}`)
            // console.log(result.data.license);
            setLicense(result.data.license);
        } catch (error) {
            console.log("Erreur lors de la recupération de cette license", error);
        }
    }



    useEffect(() => {
        fetchData();
    }, [id])

    return (
        <div className='show-license'>
            <div>
                <SideBar />
            </div>
            <div className='show-elements-details'>
                <p>ID : <span> {license._id} </span></p>
                <p>Clé de la licence : <span> {license.key} </span></p>
                <p>Nom de la licence : <span> {license.name} </span></p>
                <p>Produit  : <span> {license.productName} </span></p>
                <p>Catégorie : <span> {license.productCategory} </span></p>
                { license.productTypeSmart && <p>Type d'abonnement Netflix : <span> {license.productTypeSmart} </span></p>}
                <p>Status : <span> {license.status} </span></p>
                <p>Nombre de Compte disponible : <span> {license.number} </span></p>
                <p>Date de création : <span> {formattedDate(license.creationDate)} </span></p>
                <p>Terme : <span> {license.policy} </span></p>
                <p> N° de commandes : <span> {convertChar(license.orderNumber)} </span></p>
                <p> Emails utilisateurs  : <span> {convertChar(license.userEmail)} </span></p>
                <p>Nom Utilisateurs : <span> {convertChar(license.userName)} </span></p>
                <p>N° Tel Utilisateurs : <span> {convertChar(license.userNumberPhone)} </span></p>
            </div>
        </div>
    )
}

export default ShowLicense