import React, { useContext } from 'react';
import { DataContext } from '../Services/DataContext';
import MainLayout from '../Layouts/MainLayout';
import SideBar from '../Layouts/SideBar';
import CustomList from '../Layouts/CustomList';
// import { useParams } from 'react-router-dom';

function Blog() {

    const { posts } = useContext(DataContext);
    const barParams = { nav1: 'Nom', nav2: 'Categorie', };
    const titleItem = { name: "Blog", title1: "Nouveau Post", title2: "Posts" , title3 : "Posts", title4 : "Posts"}
    const linkItem = {  link1: "/dashboard/post/new-post", link2: "#" , link3 : "#", link4 : "#"}
    const linkList = { show : `/dashboard/post/show-post` ,edit : "/dashboard/post/edit-post"}

    return (
        <div className='Product-layout'>
            <SideBar />
            <MainLayout 
                titleItem={titleItem}
                linkItem = {linkItem}
                numberItem ={posts.length}
            />
            <CustomList
                barParams={barParams}
                listItems={[...posts].reverse()}
                linkList = {linkList}
                key1="title"
                key2="category"

                idKey = "_id"
                urlDelete = "delete-post"

            />
        </div>
    );
}

export default Blog;
