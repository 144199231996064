import { createBrowserRouter } from "react-router-dom";

import SignIn from "../Components/Connection/SignIn";
import ProtectedRoute from "../Services/ProtectedRoute";
import Connection from "../Pages/Connection";
import Dashboard from "../Pages/Dashboard";
import Order from "../Pages/Order";
import Products from "../Pages/Products";
import Share from "../Pages/Share";
import Subscription from "../Pages/Subscription";
import User from "../Pages/User";
import Blog from "../Pages/Blog";
import NewProduct from "../Components/Products/NewProduct";
import ShowProduct from "../Components/Products/ShowProduct";
import EditProduct from "../Components/Products/EditProduct";
import ShowSubscription from "../Components/Subscriptions/ShowSubscription";
import EditSubscription from "../Components/Subscriptions/EditSubscription";
import NewPost from "../Components/Blog/NewPost";
import ShowPost from "../Components/Blog/ShowPost";
import EditPost from "../Components/Blog/EditPost";
import NewOrder from "../Components/Orders/NewOrder";
import ShowOrder from "../Components/Orders/ShowOrder";
import EditOrder from "../Components/Orders/EditOrder";
import ShowShare from "../Components/Shares/ShowShare";
import EditShare from "../Components/Shares/EditShare";
import Licenses from "../Pages/Licenses";
import NewLicense from "../Components/Licenses/NewLicense";
import EditLicense from "../Components/Licenses/EditLicense";
import ShowLicense from "../Components/Licenses/ShowLicense";
import ShowUser from "../Components/Users/ShowUser";
import EditUser from "../Components/Users/EditUser";
import ReNewal from "../Pages/ReNewal";
import ShowReNewal from "../Components/ReNawal/ShowReNewal";
import RequestPaymentShare from "../Pages/RequestPaymentShare";


const token = localStorage.getItem("token");  
const router = createBrowserRouter([
    // Connections 
    { path: "/connection", element: <Connection /> },
    { path: "/", element: <SignIn /> },
    
    {
        path: "/dashboard",
        element: (
            // <ProtectedRoute isAuthenticated={token}>
                <Dashboard />
            // </ProtectedRoute>
        ),
    },

    // Order routes 
    {
        path: "/dashboard/order",
        element: (
            <ProtectedRoute isAuthenticated={token}>
                <Order />
            </ProtectedRoute>
        ),
    },
    {
        path: "/dashboard/order/new-order",
        element: (
            <ProtectedRoute isAuthenticated={token}>
                <NewOrder />
            </ProtectedRoute>
        ),
    },

    {
        path: "/dashboard/order/show-order/:id",
        element: (
            <ProtectedRoute isAuthenticated={token}>
                <ShowOrder />
            </ProtectedRoute>
        ),
    },

    {
        path: "/dashboard/order/edit-order/:id",
        element: (
            <ProtectedRoute isAuthenticated={token}>
                <EditOrder />
            </ProtectedRoute>
        ),
    },



    // Products routes
    {
        path: "/dashboard/product",
        element: (
            <ProtectedRoute isAuthenticated={token}>
                <Products />
            </ProtectedRoute>
        ),
    },
    {
        path: "/dashboard/product/new-product",
        element: (
            <ProtectedRoute isAuthenticated={token}>
                <NewProduct />
            </ProtectedRoute>
        ),
    },

    {
        path: "/dashboard/product/show-product/:id",
        element: (
            <ProtectedRoute isAuthenticated={token}>
                <ShowProduct />
            </ProtectedRoute>
        ),
    },

    {
        path: "/dashboard/product/edit-product/:id",
        element: (
            <ProtectedRoute isAuthenticated={token}>
                <EditProduct />
            </ProtectedRoute>
        ),
    },

    // Shares routes
    {
        path: "/dashboard/share",
        element: (
            <ProtectedRoute isAuthenticated={token}>
                <Share />
            </ProtectedRoute>
        ),
    },

    {
        path: "/dashboard/share/show-share/:id",
        element: (
            <ProtectedRoute isAuthenticated={token}>
                <ShowShare />
            </ProtectedRoute>
        ),
    },

    {
        path: "/dashboard/share/edit-share/:id",
        element: (
            <ProtectedRoute isAuthenticated={token}>
                <EditShare />
            </ProtectedRoute>
        ),
    },

    // Request Payment for a share routes
    {
        path: "/dashboard/request-payment",
        element: (
            <ProtectedRoute isAuthenticated={token}>
                <RequestPaymentShare />
            </ProtectedRoute>
        ),
    },
    
    // subscription routes
    {
        path: "/dashboard/subscription",
        element: (
            <ProtectedRoute isAuthenticated={token}>
                <Subscription />
            </ProtectedRoute>
        ),
    },

    {
        path: "/dashboard/subscription/show-subscription-id/:id",
        element: (
            <ProtectedRoute isAuthenticated={token}>
                <ShowSubscription />
            </ProtectedRoute>
        ),
    },

    {
        path: "/dashboard/subscription/edit-subscription/:id",
        element: (
            <ProtectedRoute isAuthenticated={token}>
                <EditSubscription />
            </ProtectedRoute>
        ),
    },
    {
        path: "/dashboard/renewal",
        element: (
            <ProtectedRoute isAuthenticated={token}>
                <ReNewal />
            </ProtectedRoute>
        ),
    },
    {
        path: "/dashboard/renewal/show-renewal/:id",
        element: (
            <ProtectedRoute isAuthenticated={token}>
                <ShowReNewal />
            </ProtectedRoute>
        ),
    },

    // user routes
    {
        path: "/dashboard/users",
        element: (
            <ProtectedRoute isAuthenticated={token}>
                <User />
            </ProtectedRoute>
        ),
    },

    {
        path: "/dashboard/user/show-user/:id",
        element: (
            <ProtectedRoute isAuthenticated={token}>
                <ShowUser />
            </ProtectedRoute>
        ),
    },

    {
        path: "/dashboard/user/edit-user/:id",
        element: (
            <ProtectedRoute isAuthenticated={token}>
                <EditUser />
            </ProtectedRoute>
        ),
    },


    // blog routes
    {
        path: "/dashboard/blog",
        element: (
            <ProtectedRoute isAuthenticated={token}>
                <Blog />
            </ProtectedRoute>
        ),
    },

    {
        path: "/dashboard/post/new-post",
        element: (
            <ProtectedRoute isAuthenticated={token}>
                <NewPost />
            </ProtectedRoute>
        ),
    },

    {
        path: "/dashboard/post/show-post/:id",
        element: (
            <ProtectedRoute isAuthenticated={token}>
                <ShowPost />
            </ProtectedRoute>
        ),
    },

    {
        path: "/dashboard/post/edit-post/:id",
        element: (
            <ProtectedRoute isAuthenticated={token}>
                <EditPost />
            </ProtectedRoute>
        ),
    },

    //Licenses

    {
        path: "/dashboard/licenses",
        element: (
            <ProtectedRoute isAuthenticated={token}>
                <Licenses />
            </ProtectedRoute>
        ),
    },
    {
        path: "/dashboard/licenses/new-license",
        element: (
            <ProtectedRoute isAuthenticated={token}>
                <NewLicense />
            </ProtectedRoute>
        ),
    },
    {
        path: "/dashboard/licenses/edit-license/:id",
        element: (
            <ProtectedRoute isAuthenticated={token}>
                <EditLicense />
            </ProtectedRoute>
        ),
    },
    {
        path: "/dashboard/licenses/show-license/:id",
        element: (
            <ProtectedRoute isAuthenticated={token}>
                <ShowLicense />
            </ProtectedRoute>
        ),
    },


    
]);

export default router;
